/**
 * Interest button
 */

.interest-btn {
  &.open {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 1;

    &.climbing {
      top: 50%;
      left: 30%;
      transform: translateY(-50%);

      @include media-breakpoint(sm) {
        left: 50%;
      }
    }

    &.skiing {
      top: 47vh;
      right: 31%;

      @include media-breakpoint(sm) {
        right: 15%;
      }

      @include media-breakpoint(lg) {
        top: 60vh;
      }

      // Max aspect ratio (4:3 and below - phones)
      @media (max-aspect-ratio: 4/3) {
        top: 50vh;
      }
    }

    &.surfing {
      bottom: 25%;
      right: 75px;

      @include media-breakpoint(md) {
        bottom: 15vh;
        right: 15%;
      }
    }

    &.skiing,
    &.surfing {
      .tooltip {
        left: auto;
        right: 0;

        .text {
          padding: 0 35px 0 15px;
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 50%;
      color: #fff;
      background-color: $secondary-colour;
      z-index: 1;
      transition: all 500ms ease;
    }

    &:hover {
      svg {
        background-color: $highlight-colour;
        transition: all 500ms ease;
      }
    }

    // Tooltip styles
    .tooltip {
      height: 100%;
      position: absolute;
      left: 1px;
      display: flex;
      align-items: center;
      z-index: 0;
      font-size: 12px;
      line-height: 1;
      border-radius: 20px;
      background-color: #fff;

      .text {
        padding: 0 15px 0 35px;
        color: $tooltip-text-colour;
        text-transform: capitalize;
      }
    }
  }
}