// FEATURED PROJECTS

.featured-projects {
  padding: 50px 0;

  display: flex;
  align-items: center;
  min-height: calc(90vh - #{$nav-height});

  @include media-breakpoint(md) {
    padding: 0;
  }

  .logo-brace {
    width: 120px;
    opacity: 0.3;

    svg {
      width: 100%;
      height: auto;
      display: block;

      .colour-stop-1 {
        color: $gradient-blue;
      }

      .colour-stop-2 {
        color: $gradient-green;
      }
    }
  }

  .content__title {
    font-size: 0.9rem; // 14.4px

    @include media-breakpoint(md) {
      font-size: 1.5rem; // 24px
    }

    @include media-breakpoint(lg) {
      font-size: 2rem; // 32px
    }

    h2,
    h3 {
      margin: 0;
      line-height: 1;
      text-transform: uppercase;
    }

    h3 {
      font-size: 1.5em;
      font-weight: 200;
      letter-spacing: $spaced-letter-spacing;
      color: $secondary-colour;
    }

    h2 {
      font-size: 3.75em;
      font-weight: 800;
    }
  }

  .content__intro {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 1em;
    font-size: 1.25em;

    @include media-breakpoint(md) {
      @include title-line();
    }

    @include media-breakpoint(lg) {
      font-size: 1.5em;
    }

    p {
      margin: 0;
    }
  }
}