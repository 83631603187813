// HOME PAGE STYLES

.home-page {
  /* ==============================
   * Hero 
  ============================== */
  .hero {
    display: flex;
    align-items: center;
    position: relative;
    min-height: calc(100vh - #{$nav-height});
  }

  .welcome {
    margin-top: -20vh;

    @include media-breakpoint(md) {
      width: 50%;
      margin-top: -10vh;
    }

    /* ==============================
     * Title 
    ============================== */
    .welcome__title {

      h1 {
        margin: 0;
        font-size: 3.75em;
        font-weight: 800;
        line-height: 1;
        color: $font-colour;

        strong {
          position: relative;
          padding: 0 15px;
          font-weight: inherit;

          @include highlight() {
            width: 0;
            bottom: 8px;
            transition: width 1s ease;

            @include media-breakpoint(lg) {
              bottom: 15px;
            }
          };
        }

        @include media-breakpoint(md) {
          font-size: 4.5em;
        }

        @include media-breakpoint(lg) {
          font-size: 6.25em;
        }
      }

      &.show-highlight {
        h1 {
          strong {
            &::before {
              width: 100%;
            }
          }
        }
      }
    }

    /* ==============================
     * Intro 
    ============================== */
    .welcome__intro {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 1.25rem;
      margin-left: auto;
      position: relative;
      font-size: 1.25em;
      @include title-line('before', 30%);

      @include media-breakpoint(sm) {
        @include title-line('before', 50%);
      }

      @include media-breakpoint(lg) {
        font-size: 1.5em;
      }

      p {
        margin: 0;
      }
    }

    .welcome__link {
      display: flex;
      justify-content: flex-end;
      margin-top: 1.25rem;

      button {
        display: flex;
        align-items: center;
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: $spaced-letter-spacing;
        background: transparent;
        border: initial;
        cursor: pointer;
        color: $secondary-colour;
      }

      svg {
        margin-left: 0.5em;
      }

      @include media-breakpoint(md) {
        font-size: 1em;
      }
    }

  }

  .logo {
    width: 40%;
    height: 115%;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    display: none;

    @include media-breakpoint(md) {
      display: block;
    }

    svg {
      position: absolute;
      width: auto;
      height: 100%;
      top: 0;
      left: 0;
    }

    .colour-stop-1 {
      color: $gradient-blue;
    }

    .colour-stop-2 {
      color: $gradient-green;
    }
  }

  /* ==============================
   * Featured projects 
  ============================== */
  .featured-projects {
    @include media-breakpoint(md) {
      margin: 100px 0;
    }

    .logo-brace {
      width: 200px;
    }
  }

}