/**
 * Helper styles
 */

/* Elements 
============================== */
// html, body {
//   height: -webkit-fill-available; // mobile viewport bug fix (safari)
// }

body {
  width: 100%;
  margin: 0;
  overflow-x: hidden; // prevent content/logo overflowing
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
  color: $font-colour;
}

img {
  width: 100%;
  height: auto;
}

/* Helpers
============================== */
#root {
  width: 100%;
  min-height: 100vh;
  position: relative;
  // mobile viewport bug fix (safari)
  // min-height: -webkit-fill-available;
}

.page {
  // @NOTE: position: absolute prevents scroll to top on route change
  // position: absolute; // needed for animation
  padding-top: $nav-height;

  a {
    position: relative;
  }
}