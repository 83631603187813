/** 
 * Mixins
 */

@mixin media-breakpoint($size) {

  $breakpoint-sm: 576px;
  $breakpoint-md: 768px;
  $breakpoint-lg: 992px;
  $breakpoint-xl: 1200px;

  @if $size == sm {
    @media (min-width: $breakpoint-sm) { @content; }
  }
  @else if $size == md {
    @media (min-width: $breakpoint-md) { @content; }
  }
  @else if $size == lg {
    @media (min-width: $breakpoint-lg) { @content; }
  }
  @else if $size == xl {
    @media (min-width: $breakpoint-xl) { @content; }
  }

}

/**
 * Line for heading
 */
@mixin title-line(
  $element: 'before', 
  $width: 50%, 
  $height: 2px, 
  $margin: 1em) {

  &::#{$element} {
    content: "";
    width: $width;
    height: $height;
    display: block;
    margin-right: $margin;
    background-color: $secondary-colour;
    @content;
  }

}

/**
 * Highlight word
 */
@mixin highlight() {
  &::before {
    content: "";
    width: 100%;
    height: 35%;
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: -1;
    background-image: linear-gradient(45deg, $gradient-blue, $gradient-green);
    @content;
  }
}