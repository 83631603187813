// NAV STYLES

header {
  width: 100%;
  position: absolute;
  z-index: 1;

  .nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: ($default-padding / 2) 0 0;
    padding: 30px 0;
  
    @include media-breakpoint(md) {
      justify-content: flex-end;
    }
  
    .nav__item {
      margin: 0 0.5em;
      padding-bottom: 0.5em;
      font-size: 0.875em;
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: $spaced-letter-spacing;
      color: $secondary-colour;
      transition: all 500ms ease-in-out;

      @include media-breakpoint(sm) {
        margin: 0 1.5em;
      }
      
      &:hover {
        color: $highlight-colour;
      }
  
      &.nav__item--home {
        margin: 0 auto 0 0;

        svg {
          width: 40px;
          height: auto;

          .colour-stop-1 {
            color: $gradient-blue;
          }
      
          .colour-stop-2 {
            color: $gradient-green;
          }
        }
      }

      &.nav__item--home {
        padding-bottom: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        border-bottom: 1px solid $secondary-colour;
      }

    }
  }
  
}
