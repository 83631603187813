/**
 * About page component
 */

.about-page {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  // position: absolute; // needed to layer over nav
  // top: 0; // needed to layer over nav

  .hero {
    width: 100%;
    height: 100vh;
    /* perspective stuff */
    perspective: 400px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: perspective 1s;

    .hero__content {
      width: 100%;
      height: 100%;
      transform: translateZ(0);
    }

    .background-image {
      width: 100%;
      height: 100%;
      position: absolute;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right;

        @include media-breakpoint(md) {
          object-position: center 25%;
        }

        &.mountain-clipping {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

      }
    
      &::after {
        content: "";
        width: 100%;
        height: 50vh;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to bottom, #fff, $transparent);
      }
    }

    /* ==============================
     * About page: Title 
    ============================== */
    .title-container {
      width: 90%; // for svg
      position: absolute;
      margin-top: 75px;
      right: 5%;

      @include media-breakpoint(md) {
        width: 58%; // for svg
        max-width: 1000px; // for svg
        right: 15%;
        margin: 30px auto 0 auto;
      }

      svg {
        width: 100%;
        height: 100%;

        .colour-stop-1 {
          color: rgba($sky-colour, 0.3);
        }

        .colour-stop-2 {
          color: $transparent;
        }

        // "me" text
        .text-me {
          transform: translate(901px, 700px);

          @include media-breakpoint(lg) {
            transform: translate(901px, 680px);
          }
        }
      }
    }

  } 
  
  /* ==============================
   * About page: Buttons 
  ============================== */
  .interest-btn {
    cursor: pointer;
    transition: all 500ms ease;
  }

  // Main footer component
  .footer {
    margin-top: 1rem;
  }

}