/** 
 * Pulse animation
 * Used: About.js
 */

.pulse {
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    background-color: $secondary-colour;
    animation: pulse 1s infinite linear;
  }

  &:hover {
    &::before {
      animation: initial; /* unset animation on hover */
    }
  }

}

/* pulse button */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  70% {
    transform: scale(2.5);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}