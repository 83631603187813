// PROJECT ITEM
.project {
  padding: 50px 0;

  @include media-breakpoint(md) {
    padding: 100px 0;
  }

  .project__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    font-size: 1rem;

    @include media-breakpoint(md) {
      font-size: 1.25rem; // 20px
    }

    @include media-breakpoint(lg) {
      margin-top: 0;
    }
  }

  .project__title {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 1.5em;

    @include media-breakpoint(md) {
      @include title-line("before", 100%, 2px, 1em) {
        position: absolute;
        right: 100%;
        max-width: 100px; // limit size
      }
    }

    h3 {
      margin: 0;
      font-size: 2.5em;

      @include media-breakpoint(lg) {
        font-size: 3.2em;
      }
    }
  }

  .project__lead {
    font-weight: 500;
    color: $secondary-colour;
  }

  .project__link {
    margin-top: auto;
    align-self: flex-start;
    position: relative;
    padding: 0 0.25em;
    font-weight: 700;
    text-decoration: none;
    color: $primary-colour;
    @include highlight();
  }

  .project__image {
    border-radius: 20px;
    overflow: hidden;
  }

  /* ==============================
   * Right aligned project 
  ============================== */
  &[data-placement="right"] {

    .project__title {
      &::before {
        left: 100%;
      }
    }
  }

  /* ==============================
   * Communicator
  ============================== */
  &.communicator,
  &.web-app {
    // .project__image {
    //   background: linear-gradient(
    //     58.22deg,
    //     rgba(#375b7b, 0.3),
    //     rgba(#665f9c, 0.3),
    //     rgba(#b1539a, 0.3),
    //     rgba(#ea476f, 0.3),
    //     rgba(#f86624, 0.3)
    //   );
    // }
  }

  /* ==============================
   * Energy app
  ============================== */
  &.energy-app {
    // .project__image {
    //   // ** REAL COLOURS **
    //   // background: linear-gradient(
    //   //   45deg,
    //   //   rgba(#00bcfd, 0.3),
    //   //   rgba(#23d2b5, 0.3),
    //   // );

    //   // Tweaked colours (not real)
    //   background: linear-gradient(
    //     45deg,
    //     rgba(#14c4ff, 0.3),
    //     rgba(#2ce6c7, 0.3),
    //   );
    // }

    .project__link {
      opacity: 0.2;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  /* ==============================
   * Web app
  ============================== */
  // &.web-app {
  //   .project__image {
  //     padding: 80px 40px;
      
  //     img {
  //       width: 100%;
  //       height: auto;
  //     }
  //   }

  //   .project__link {
  //     opacity: 0.2;
  //     pointer-events: none;
  //     cursor: not-allowed;
  //   }
  // }

}