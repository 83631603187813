/**
 * Interest component
 */

.interest {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* perspective stuff */
  perspective: 400px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: perspective 1s;


  .background-container {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .interest__content {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 20px;

    .interest__title {
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem;

      // highlight
      &::before {
        content: "";
        width: 0;
        height: 35%;
        position: absolute;
        left: 0;
        z-index: -1;
        bottom: 20px;
        background-image: linear-gradient(45deg, $gradient-blue, $gradient-green);
        transition: width 1s ease;
      }

      &.show-highlight {
        &::before {
          width: 100%;
        }
      }

      svg {
        width: 100%;
        height: auto;
        color: $dark-grey;

        &.show-bg {
          background-size: 100% 35%;
        }
      }
    }

    .interest-btn {
      &.close {
        position: fixed;
        top: 75px;
        left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;

        @include media-breakpoint(lg) {
          top: 100px;
        }
      }
    }
  }

  // Position skiing image
  &.interest--skiing {
    .background-container {
      img {
        object-position: left;
      }
    }
  }
}